import React from 'react';
import { Box, Button, Flex, Image, Link, Spacer } from '@chakra-ui/react';
import Facebook from "./assets/social-media-icons/facebook.png";
import Twitter from "./assets/social-media-icons/twitter.png";
import opensea from "./assets/social-media-icons/opensea.png";

const NavBar = ({ accounts, setAccounts }) => {
    const isConnected = Boolean(accounts[0]);

    async function connectAccount() {
        if (window.ethereum) {
            const accounts = await window.ethereum.request({
                method: "eth_requestAccounts",
            });
            setAccounts(accounts);
        }
    }

    return (
        <Flex justify="right" align="center" padding="30px" color="white">
            {/* Left Side - Socials */}
            <Link href="https://opensea.io/collection/4854album">
                <Image src={opensea} boxSize="42px" margin="0 15px" />
            </Link>
                <Link href="https://twitter.com/Chalks_4854">
                    <Image src={Twitter} boxSize="42px" margin="0 15px" />
                </Link>

            {/* Right Side - Sections&Connect */}
            <Flex margin="10px" color="white" font-weight="600">
            <a href="#about-anchor">About</a>
            </Flex>
            {/* Connect */}
            {isConnected ? (
                <p>Connected</p>
            ) : (
                <button className="connect-button" onClick={connectAccount}>Connect</button>
            )}

        </Flex>
    )
}

export default NavBar;