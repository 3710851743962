import {useState} from 'react';
import { ethers, BigNumber, utils } from 'ethers';
import FourtyEight54 from './FourtyEight54.json';
import { ThemeContext } from '@emotion/react';

const FourtyEight54Address = "0x9dF03b01C55200a0a4ced5D0B9e04277b66Ff1AA";

const MainMint = ({ accounts, setAccounts}) => {
    const [mintAmount, setMintAmount] = useState(1);
    const isConnected = Boolean(accounts[0]);

    async function handleMint() {
        if (window.ethereum) {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const contract = new ethers.Contract(
                FourtyEight54Address,
                FourtyEight54,
                signer
            );
            console.log(signer)
            try {
                const amount = mintAmount;
                const id = 1;
                const response = 
                        await contract.mint(
                            id, 
                            amount, 
                            { value: BigNumber.from("15000000000000000") },
                            )
                        
                console.log('response: ', response);
            } catch (err) {
                console.log(err)
                alert("Not enough ETH in wallet, or you rejected the transaction.")
            }
        }
    }

    async function handleMint2() {
        if (window.ethereum) {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const contract = new ethers.Contract(
                FourtyEight54Address,
                FourtyEight54,
                signer
            );
            try {
                const amount = mintAmount;
                const id = 2;
                const response = 
                        await contract.mint(
                            id, 
                            amount, 
                            { value: BigNumber.from("15000000000000000") },
                            )
                        
                console.log('response: ', response);
            } catch (err) {
                console.log("error: ", err)
                alert("Not enough ETH in wallet, or you rejected the transaction.")
            }
        }
    };

    async function handleMint3() {
        if (window.ethereum) {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const contract = new ethers.Contract(
                FourtyEight54Address,
                FourtyEight54,
                signer
            );
            try {
                const amount = mintAmount;
                const id = 3;
                const response = 
                        await contract.mint(
                            id, 
                            amount, 
                            { value: BigNumber.from("15000000000000000") },
                            )
                        
                console.log('response: ', response);
            } catch (err) {
                console.log("error: ", err)
                alert("Not enough ETH in wallet, or you rejected the transaction.")
            }
        }
    };

    async function handleMint4() {
        if (window.ethereum) {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const contract = new ethers.Contract(
                FourtyEight54Address,
                FourtyEight54,
                signer
            );
                try {
                    const amount = mintAmount;
                    const id = 4;
                    const response = 
                            await contract.mint(
                                id, 
                                amount, 
                                { value: BigNumber.from("15000000000000000") },
                                )
                            
                    console.log('response: ', response);
                } catch (err) {
                    console.log("error: ", err)
                    alert("Not enough ETH in wallet, or you rejected the transaction.")}
                }
    };

    async function handleMint5() {
        if (window.ethereum) {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const contract = new ethers.Contract(
                FourtyEight54Address,
                FourtyEight54,
                signer
            );
            try {
                const amount = mintAmount;
                const id = 5;
                const response = 
                        await contract.mint(
                            id, 
                            amount, 
                            { value: BigNumber.from("15000000000000000") },
                            )
                        
                console.log('response: ', response);
            } catch (err) {
                console.log("error: ", err)
                alert("Not enough ETH in wallet, or you rejected the transaction.")}
            }
        };

    const handleMinus = () => {
        if (mintAmount <= 1) return;
        setMintAmount(mintAmount - 1);
    };

    const handlePlus = () => {
        if (mintAmount >= 5) return;
        setMintAmount(mintAmount + 1);
    };

    return (
        <div>
            {isConnected ? (
                <div className="mint-box-main">
                    <div className="mint-box">
                    <div className="announce-text"><iframe width="560" height="315" src="https://www.youtube.com/embed/Cq9zkY4JsLc?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>
                        <br></br>Track 1: 4854 (0.015 ETH)<br></br>
                        <button className="mint-button" onClick={handleMint}>Mint Track 1</button>
                        <button className="number-button-left" onClick={handleMinus} >-</button>
                        <input className="number-box" type="number" value={mintAmount} />
                        <button className="number-button-right" onClick={handlePlus} >+</button>
                        
                    </div>
                    <div className="mint-box">
                    <div className="announce-text"><iframe width="560" height="315" src="https://www.youtube.com/embed/JdNhrnPVse4?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>
                        <br></br>Track 2: Creature No Kill Ape (0.015 ETH)<br></br>
                        <button className="mint-button" onClick={handleMint2}>Mint Track 2</button>
                        <button className="number-button-left" onClick={handleMinus} >-</button>
                        <input className="number-box" type="number" value={mintAmount} />
                        <button className="number-button-right" onClick={handlePlus} >+</button>
                    </div>
                    <div className="mint-box">
                    <div className="announce-text"><iframe width="560" height="315" src="https://www.youtube.com/embed/uZ8Hawa_OtI?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>
                        <br></br>Track 3: If I Were A Wizard (0.015 ETH)<br></br>
                        <button className="mint-button" onClick={handleMint3}>Mint Track 3</button>
                        <button className="number-button-left" onClick={handleMinus} >-</button>
                        <input className="number-box" type="number" value={mintAmount} />
                        <button className="number-button-right" onClick={handlePlus} >+</button>

                    </div>
                    <div className="mint-box">
                    <div className="announce-text"><iframe width="560" height="315" src="https://www.youtube.com/embed/zgRjls0mTEo?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>
                        <br></br>Track 4: The Blessing (0.015 ETH)<br></br>
                        <button className="mint-button" onClick={handleMint4}>Mint Track 4</button>
                        <button className="number-button-left" onClick={handleMinus} >-</button>
                        <input className="number-box" type="number" value={mintAmount} />
                        <button className="number-button-right" onClick={handlePlus} >+</button>
                    </div>
                    
                    <div className="mint-box">
                    <div className="announce-text"><iframe width="560" height="315" src="https://www.youtube.com/embed/bOgtJzFOTY8?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>
                        <br></br>Track 5: 9404 Love (0.015 ETH)<br></br>
                        <button className="mint-button" onClick={handleMint5}>Mint Track 5</button>
                        <button className="number-button-left" onClick={handleMinus} >-</button>
                        <input className="number-box" type="number" value={mintAmount} />
                        <button className="number-button-right" onClick={handlePlus} >+</button>
                    </div>
                    
                </div>
            ) : (
                <p>Please connect to access Mint.</p>
            )}
        </div>
    )
}

export default MainMint;