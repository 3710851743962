import './App.css';
import { useState } from 'react';
import MainMint from './MainMint';
import NavBar from './NavBar';

function App() {
  const [accounts, setAccounts] = useState([]);

  return (
    <div className="App">
      <div className="moving-background">
        <div className="content-container">
          <NavBar accounts={accounts} setAccounts={setAccounts} />
          <div className="announce-text">Chalks the Creature Presents: <br></br><p className="text-size">4854</p></div>
          <MainMint accounts={accounts} setAccounts={setAccounts} />
        </div>
        <div className="about-text"><p className="about" id="about-anchor">ABOUT</p><br></br>
        4854 is an album from the perspective of Chalks, a glitched Creature who deals with differences from his brethren through song.  Ownership of the 4854 album entitles holders to future music drops and other related artistic endeavors (vinyl redemption raffles, audiovisual drops, collabs and more).</div>
      </div>
      <div className="album-cover"></div>
    </div>
  );
}

export default App;
